.react-datepicker-wrapper {
  width: 100%;
  .react-datepicker__input-container input {
    width: 100%;
    border: $input-border-width solid $input-border-color;
    padding: $input-padding-y $input-padding-x;
    border-radius: $input-border-radius;
    background-color: $input-bg;
    font-size: $input-font-size;
    line-height: $input-line-height;
    &:focus {
      border-color: $input-focus-border-color;
    }
    &:focus-visible {
      outline: none;
    }
  }
}
