.floating-label {
  position: relative;
  margin-bottom: 1.5rem;
}

.floating-label input,
.floating-label textarea {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 1.25rem 0.75rem 0.25rem;
  width: 100%;
  transition:
    border-color 0.2s,
    box-shadow 0.2s;
}

.floating-label label {
  position: absolute;
  top: 0.75rem;
  left: 0.75rem;
  transition: all 0.2s;
  pointer-events: none;
  font-size: 1rem;
  color: #6c757d;
}

.floating-label input:focus + label,
.floating-label input:not(:placeholder-shown) + label,
.floating-label textarea:focus + label,
.floating-label textarea:not(:placeholder-shown) + label {
  top: -0.5rem;
  left: 0.75rem;
  font-size: 0.75rem;
  background: white;
  padding: 0 0.25rem;
}
.floating-label input:focus,
.floating-label textarea:focus {
  border-color: #007bff;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 123, 255, 0.25);
}

/* .notification-badge-dot {
  width: 10px;
  height: 10px;
  background-color:#E7584F;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 10px;
  right: 10px;
} */

/* Custom.css */

.notification-badge-dot {
  width: 12px;
  height: 12px;
  /* background-color: #f44336; */
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
  position: absolute;
  top: 10px;
  right: 10px;
}

/* 
.notification-badge {
  width: 10px;
  height: 10px;
  background-color:#f44336;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  top: 5px; 
  right: 5px; 
} */
.table .form-select.small-select {
  width: 100px !important;
  /* padding: 2px 4px !important;  */
  font-size: 12px !important;
}
