// auth.scss

.auth-bg {
  display: flex;
  min-height: 100vh;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-image: url('../../images/auth-bg.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  .img-shadow {
    box-shadow: $img-shadow;
  }
}

.auth-or-border {
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 42%;
    height: 1px;
    background-color: $gray-300;
  }
  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    width: 42%;
    height: 1px;
    background-color: $gray-300;
  }
}
