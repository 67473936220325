// badge.scss

.badge {
  &.badge-sm {
    padding: 1px 6px;
    font-size: 12px;
  }
}

@each $color, $value in $theme-colors {
  .badge {
    &.bg-soft-#{$color} {
      background-color: rgba($value, 0.15);
      border: 1px solid rgba($value, 0.5);
      color: $value;
    }
  }
}
