// buttons.scss

.btn-list {
  margin: -2px;
  .btn,
  .nav-link {
    margin: 2px;
  }
  .btn-group {
    margin: 2px;
    .btn {
      margin: 0px;
    }
  }
}

@each $color, $value in $theme-colors {
  .btn {
    &.btn-#{$color} {
      box-shadow: 0px 4px 6px rgba(shade-color($value, 60%), 0.2);
    }
    &.btn-soft-#{$color} {
      background-color: rgba($value, 0.15);
      border-color: rgba($value, 0.5);
      color: $value;
      &:hover,
      &.active,
      &:active,
      &.focus,
      &:focus-visible {
        background-color: rgba($value, 0.25) !important;
        color: $value !important;
        border-color: gba($value, 0.8) !important;
      }
    }
  }
}

// btn custom width
.btn-w-md {
  width: 100px;
}

// table-btn
.btn {
  &.table-btn {
    height: 20px;
    width: 20px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $font-size-sm;
    i {
      position: relative;
      top: 1.5px;
    }
  }
}
