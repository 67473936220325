// custom-variables.scss

$font-size-xs: 0.875rem;

// Topbar

// Left-sidebar
$left-bar-width: 230px;
$left-bar-width-sm: 90px;
$left-bar-width-none: 0px;
$layout-border-color: #eef0f4;
$layout-shadow: 0px 0px 8px rgba($dark, 0.1);
$left-nav-font-size: 18px;
$left-nav-icon-size: 20px;
$line-height-xs: 1.2;
$left-nav-link-color: #767d99;

// Topbar
$topbar-height: 70px;
$search-input-width: 376px;
$top-drop-btn-height: $topbar-height;
$navlink-light-height: 42px;
$top-search-icon-color: #767d99;

// card-border
$card-border: #eef0f4;
$card-title: 20px;
$card-title-mb: 16px;

// dropdown -width
$dropdown-xl-width: 340px;
$dropdown-sm-width: 320px;

// img-shadow
$img-shadow: 0px 4px 5px 0px rgba($dark, 0.15);

// page title
$page-title-size: 24px;
$page-title-size-sm: 20px;

// table
$table-data-padding-y: 0.4rem;
$table-data-padding-x: 0.625rem;

// footer
$footer-height: 50px;
