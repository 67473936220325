// dropdown.scss

.dropdown {
  .dropdown-menu {
    box-shadow: $dropdown-box-shadow;
    &.dropdown-xl {
      width: $dropdown-xl-width;
    }
  }
}

@include media-breakpoint-down(sm) {
  .dropdown-menu {
    &.dropdown-xl {
      width: $dropdown-sm-width;
    }
  }
}
