// form.scss

.form-label {
  line-height: $line-height-sm;
}
.form-check-label {
  font-size: $font-size-sm;
}

.custom-form-lg {
  .form-label {
    font-size: $font-size-base + 0.125;
  }
  .form-control {
    padding: $input-padding-y-lg $input-padding-x-lg;
    font-size: $input-font-size-lg;
  }
}

// input-icon-content
.input-icon-content {
  position: relative;
  .input-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    color: $form-label-color;
  }
}

.custom-fr-group {
  margin-bottom: 16px;
}
