// pagetile.scss
.page-title-content {
  padding: 15px 0;
  .page-title {
    color: #0148b2;
    font-size: $page-title-size;
    margin-bottom: 0;
  }
  .breadcrumb {
    margin-bottom: 0;
    .breadcrumb-item {
      a {
        color: $text-muted;
      }
    }
  }
}

// responsive
@include media-breakpoint-down(sm) {
  .page-title-content {
    .page-title {
      font-size: $page-title-size-sm;
    }
    .breadcrumb {
      .breadcrumb-item {
        font-size: $font-size-sm;
      }
    }
  }
}
@include media-breakpoint-down(xl) {
  .page-title-content {
    .page-title {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
