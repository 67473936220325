// background.scss

@each $color, $value in $theme-colors {
  .bg-soft-#{$color} {
    background-color: rgba($value, 0.15);
  }
  .border-soft-#{$color} {
    border-color: rgba($value, 0.5) !important;
  }

  .bg-border-soft-#{$color} {
    background-color: rgba($value, 0.15);
    border: 1px solid rgba($value, 0.5) !important;
  }
}
