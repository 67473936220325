// topbar.scss
.topbar {
  background-color: $white;
  height: $topbar-height;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $layout-border-color;
  box-shadow: $layout-shadow;
  position: fixed;
  top: 0;
  left: $left-bar-width;
  right: 0;
  padding: 0 calc($grid-gutter-width / 2);
  z-index: 997;

  .top-search-input-content {
    .top-search-input {
      min-width: $search-input-width;
      position: relative;
      .top-search-icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 16px;
        color: $top-search-icon-color;
      }
      .form-control {
        padding-left: 48px;
      }
    }
  }

  // topbar-right-content
  .topbar-right-content {
    .topbar-icon {
      .topbar-avatar {
        border: 1px solid rgba($gray-600, 0.4);
        .noti-msg-icon {
          position: absolute;
          display: block;
          height: 9px;
          width: 9px;
          top: 6px;
          right: 10px;
          background-color: $danger;
          border-radius: 10px;
          border: 1px solid $white;
        }
      }
    }
    .top-drop-btn {
      border: none !important;
      height: $top-drop-btn-height;
    }
    .topbar-user {
      .top-user-drop-btn {
        display: flex;
        align-items: center;
        text-align: left;
      }
    }
    .topbar-noti-content {
      .dropdown-menu {
        .dropdown-item {
          white-space: inherit;
          padding-top: 12px;
          padding-bottom: 12px;
          .noti-item {
            .noti-title {
              font-size: $font-size-sm;
            }
            .noti-time,
            .noti-subtitle {
              color: $text-muted;
            }
          }
          &.active,
          &:active {
            .noti-item {
              .noti-title {
                color: $white;
              }
              .noti-time,
              .noti-subtitle {
                color: $light;
              }
            }
          }
        }
      }
    }
  }
}
