.toggle-submenu {
  cursor: pointer;
  /* margin-left: 30px; */
}

.nav-item {
  position: relative;
}

.submenu {
  color: #0047af;
  list-style-type: none;
}

.nav-item:hover .submenu,
.submenu.open {
  display: block;
}

.avatar-icon {
  cursor: pointer;
  color: #767d99;
  font-size: 1.5rem;
  margin-left: auto;
  padding: 10px;
  transition: all 0.3s ease;
}

.leftbar .leftbar-logo {
  height: 70px;
  width: 100%;
  display: flex;
  margin-left: 15px;
}
.notification-badge {
  width: 8px;
  height: 8px;
  background-color: #f44336;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  font-size: 10px;
  top: 2px;
  right: 35px;
}

.notification-badge1 {
  display: inline-block;
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #f44336;
  border-radius: 50%;
  margin-left: 5px;
  /* top: 5px;
  right: 5px; */
}
