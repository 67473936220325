// helper.scss

.text-muted {
  color: $text-muted !important;
}

.auth-img {
  border-radius: 50%;
  border: 2px solid white;
  width: 26px;
}

.add-btn {
  position: absolute;
  right: 10px;
  bottom: 15px;
}

.pointer-cursor {
  cursor: pointer;
}
