// simplebar css
.simplebar-scrollable-y[data-simplebar] {
  // height: 100%;
  // max-height: 100%;
  // min-height: 100%;
  .simplebar-track.simplebar-vertical {
    width: 8px;
  }
  .simplebar-scrollbar:before {
    background: rgba($dark, 0.4);
  }
}
