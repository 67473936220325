// avatar.scss

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $border-radius;
}
.avatar-xxxl {
  height: 100px;
  width: 100px;
  .avatar-icon {
    font-size: 65px;
  }
}
.avatar-xxl {
  height: 75px;
  width: 75px;
  .avatar-icon {
    font-size: 40px;
  }
}
.avatar-xl {
  height: 65px;
  width: 65px;
  .avatar-icon {
    font-size: 35px;
  }
}
.avatar-lg {
  height: 55px;
  width: 55px;
  .avatar-icon {
    font-size: 25px;
  }
}
.avatar-md {
  height: 42px;
  width: 42px;
  .avatar-icon {
    font-size: 24px;
  }
}
.avatar-sm {
  height: 32px;
  width: 32px;
  border-radius: $border-radius-sm;
  .avatar-icon {
    height: 18px;
    width: 18px;
  }
}
.avatar-xs {
  height: 24px;
  width: 24px;
  border-radius: $border-radius-sm;
  .avatar-icon {
    font-size: 11px;
  }
}

// avatar-img
.avatar-img-sm {
  height: 32px;
  width: 32px;
}
.avatar-img-md {
  height: 52px;
  width: 52px;
}

.avatar-img-xxl {
  height: 120px;
  width: 120px;
  object-fit: cover;
}
.avatar-team {
  img:not(:first-child) {
    margin-left: -8px;
  }
}

// custom avatar size
@for $i from 10 through 100 {
  .avatar-#{$i} {
    height: 1px * $i;
    width: 1px * $i;
  }
}
