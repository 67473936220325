//modal.scss

.modal {
  backdrop-filter: blur(8px);
  .modal-header {
    border-bottom: none;
    padding-bottom: 0px;
    .modal-title {
      font-size: $font-size-lg;
    }
  }
  .modal-footer {
    border-top: none;
    padding-top: 0;
  }
}
