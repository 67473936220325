// react-select.scss
.custom-form-input {
  // custom-react-select
  .css-13cymwt-control,
  .css-t3ipsp-control {
    .css-1fdsijx-ValueContainer,
    .css-3w2yfm-ValueContainer {
      padding: 12px 14px 6px;
      .css-1p3m7a8-multiValue {
        border-radius: $input-border-radius;
        background-color: rgba($primary, 0.15);
        border: 1px solid rgba($primary, 0.5);
        margin-left: 0;
        margin-right: 5px;
        .css-wsp0cs-MultiValueGeneric {
          font-size: 12px;
          line-height: 14px;
          padding: 2px 4px;
        }
      }
    }
    .css-1jqq78o-placeholder {
      color: $input-placeholder-color;
    }
    .css-wsp0cs-MultiValueGeneric,
    .css-12a83d4-MultiValueRemove {
      background-color: transparent;
      color: $primary;
    }
  }
  .css-13cymwt-control {
    border-color: $input-border-color;
    background-color: $input-bg;
  }
  .css-t3ipsp-control,
  .css-t3ipsp-control:hover {
    border-color: $input-focus-border-color;
    box-shadow: none;
  }
  .css-13cymwt-control {
    &:hover {
      border-color: $input-border-color;
    }
  }
  .css-1nmdiq5-menu {
    .css-1n6sfyn-MenuList {
      & > div {
        color: $form-select-color;
        &:hover,
        &:first-child {
          background-color: rgba($primary, 0.18);
          color: $primary;
        }
      }
    }
  }
  .css-b62m3t-container {
    .css-13cymwt-control,
    .css-t3ipsp-control {
      .css-3w2yfm-ValueContainer,
      .css-1fdsijx-ValueContainer {
        padding: 5px 14px 5px;
        & > div {
          line-height: $input-line-height;
        }
      }
    }
  }
  &.custom-form-input-sm {
    .css-13cymwt-control,
    .css-t3ipsp-control {
      min-height: 34px !important;
      .css-1fdsijx-ValueContainer,
      .css-3w2yfm-ValueContainer {
        padding: 8px 0px 2px 14px;
      }
    }
  }
}
