// card.scss

.card {
  border-color: $card-border;
  box-shadow: $card-box-shadow;
  margin-bottom: $grid-gutter-width;
  .card-title {
    font-size: $card-title;
    font-weight: $font-weight-medium;
    margin-bottom: $card-title-mb;
    color: #337ab7;
  }
  .card-text {
    color: $gray-700;
  }
}
