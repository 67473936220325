.leftbar {
  background-color: $white;
  width: $left-bar-width;
  padding-bottom: 50px;
  height: 100vh;
  border-right: 1px solid $layout-border-color;
  box-shadow: $layout-shadow;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 998;
  .leftbar-logo {
    height: $topbar-height;
    width: 100%;
    display: flex;

    justify-content: center;
    align-items: center;

    .logo-lg {
      display: block;
    }
    .logo-sm {
      display: none;
    }
  }
  .leftbar-menu-sroll {
    height: 100%;
    min-height: 100%;
    max-height: 100%;
  }

  // menu-list
  .menu-list {
    padding: 24px;
    .custom-leftbar {
      .nav-link {
        font-size: $left-nav-font-size;
        font-weight: $font-weight-base;
        line-height: $line-height-xs;
        border: 1px solid transparent;
        color: $left-nav-link-color;
        margin-bottom: 10px;
        .left-nav-icon {
          font-size: $left-nav-icon-size;
          position: relative;
          top: 1px;
        }
        .menu-link-text {
          margin-left: 12px;
        }
        &.active {
          background-color: rgba($primary, 0.12);
          border-color: rgba($primary, 0.15);
          color: $primary;
        }
      }
    }
  }
}
