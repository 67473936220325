// navtabs.scss

.custom-navtab {
  .nav-item {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
    .nav-link {
      background-color: $light;
      color: $gray-700;
      &.active {
        background-color: $primary;
        color: $white;
      }
    }
  }
}
