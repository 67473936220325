// page-content.scss

.page-wrapper {
  // min-height: 100vh;
  min-height: calc(100vh - #{$top-drop-btn-height} - #{$border-width});
  position: absolute;
  top: 70px;
  left: $left-bar-width;
  right: 0;
  .page-content {
    padding: 0 calc($grid-gutter-width / 2) $footer-height;
  }
}

// leftbar-sm
[data-sidebar-size='sm'] {
  .leftbar {
    width: $left-bar-width-sm;
    .leftbar-logo {
      .logo-lg {
        display: none;
      }
      .logo-sm {
        display: block;
      }
    }
    .menu-list {
      padding: 20px 18px;
      .left-menu-title {
        display: none;
      }
      .navbar {
        padding: 0;
      }
      .custom-leftbar {
        .nav-link {
          text-align: center;
          .left-nav-icon {
            font-size: 22px;
          }
          .menu-link-text {
            display: none;
          }
        }
      }
    }
  }
  .topbar,
  .page-wrapper {
    left: $left-bar-width-sm;
  }
}

@include media-breakpoint-down(md) {
  [data-sidebar-size='none'] {
    .leftbar {
      width: $left-bar-width-none;
      .leftbar-logo {
        display: none;
        .logo-lg {
          display: none;
        }
        .logo-sm {
          display: none;
        }
      }
    }
    .topbar,
    .page-wrapper {
      left: $left-bar-width-none;
    }
  }
}

// responsive
@include media-breakpoint-down(md) {
  .topbar {
    .topbar-icon {
      .dropdown-menu {
        padding: $dropdown-padding-y $dropdown-padding-y;
      }
      .top-search-input-content {
        .top-search-input {
          min-width: 100%;
        }
      }
    }
  }
}
