@import '../node_modules/bootstrap/scss/mixins/banner';
@include bsBanner('');

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/bootstrap';

// scss-docs-start import-stack
// Configuration
@import '../node_modules/bootstrap/scss/functions';
@import './scss/variables';
@import './scss/custom-variables';
@import '../node_modules/bootstrap/scss/variables-dark';
@import '../node_modules/bootstrap/scss/maps';
@import '../node_modules/bootstrap/scss/mixins';
@import '../node_modules/bootstrap/scss/utilities';

// Layout & components
@import '../node_modules/bootstrap/scss/root';
@import '../node_modules/bootstrap/scss/reboot';
@import '../node_modules/bootstrap/scss/type';
@import '../node_modules/bootstrap/scss/images';
@import '../node_modules/bootstrap/scss/containers';
@import '../node_modules/bootstrap/scss/grid';
@import '../node_modules/bootstrap/scss/tables';
@import '../node_modules/bootstrap/scss/forms';
@import '../node_modules/bootstrap/scss/buttons';
@import '../node_modules/bootstrap/scss/transitions';
@import '../node_modules/bootstrap/scss/dropdown';
@import '../node_modules/bootstrap/scss/button-group';
@import '../node_modules/bootstrap/scss/nav';
@import '../node_modules/bootstrap/scss/navbar';
@import '../node_modules/bootstrap/scss/card';
@import '../node_modules/bootstrap/scss/accordion';
@import '../node_modules/bootstrap/scss/breadcrumb';
@import '../node_modules/bootstrap/scss/pagination';
@import '../node_modules/bootstrap/scss/badge';
@import '../node_modules/bootstrap/scss/alert';
@import '../node_modules/bootstrap/scss/progress';
@import '../node_modules/bootstrap/scss/list-group';
@import '../node_modules/bootstrap/scss/close';
@import '../node_modules/bootstrap/scss/toasts';
@import '../node_modules/bootstrap/scss/modal';
@import '../node_modules/bootstrap/scss/tooltip';
@import '../node_modules/bootstrap/scss/popover';
@import '../node_modules/bootstrap/scss/carousel';
@import '../node_modules/bootstrap/scss/spinners';
@import '../node_modules/bootstrap/scss/offcanvas';
@import '../node_modules/bootstrap/scss/placeholders';

// Helpers
@import '../node_modules/bootstrap/scss/helpers';

// Utilities
@import '../node_modules/bootstrap/scss/utilities/api';
@import 'react-toastify/dist/ReactToastify.css';
// scss-docs-end import-stack

// Layout scss
@import './scss/layout/left-sidebar';
@import './scss/layout/topbar';
@import './scss/layout/page-title';
@import './scss/layout/page-content';
@import './scss/layout/footer';

// toast
@import 'react-toastify/dist/ReactToastify.css';
// custom scss
@import './scss/components/helper';
@import './scss/components/background';
@import './scss/components/buttons';
@import './scss/components/badges';
@import './scss/components/form';
@import './scss/components/card';
@import './scss/components/utilities';
@import './scss/components/avatar';
@import './scss/components/navtabs';
@import './scss/components/table';
@import './scss/components/badge';
@import './scss/components/modal';
@import './scss/components/progressbar';
@import './scss/components/dropdown';

// pages
@import './scss/pages/auth';

// plugins
@import './scss/plugins/datepicker';
@import './scss/plugins/simplebar';
@import './scss/plugins/datatable';
@import './scss/plugins/react-select';
